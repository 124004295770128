import React from 'react'

import SEO from '../components/shared/seo'

const NotFoundPage = () => (
  <main>
    <SEO title="404: Страница не найдена" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </main>
)

export default NotFoundPage
